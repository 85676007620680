import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_tabs_container()
})

function dhsv_vc_tabs_container() {
  $('.dhsv_vc_tabs_container').each(function() {
    let allListElements = $('li')
    $(this)
      .find(allListElements)
      .appendTo('ul.nav')
    $('p').each(function() {
      var $this = $(this)
      if ($this.html().replace(/\s|/g, '').length == 0) $this.remove()
    })
  })
}
